@import "../../node_modules/@docsearch/css/dist/button.css";
@import "../../node_modules/@docsearch/css/dist/modal.css";

@import "./common/reset.css";
@import "./common/fonts.css";
@import "./common/utils.css";
@import "./common/header.css";
@import "./common/icon.css";
@import "./common/skip-to-content-link.css";
@import "./common/footer.css";
@import "./common/footer-list.css";
@import "./common/tokens/colors.css";
@import "./common/tokens/typography.css";

html {
	/* spacings */

	--u-page-spacing: clamp(1.25rem, 5vw, 5rem);

	--u-max-width: 90rem;
	--page-spacing--fluid: clamp(1.875rem, 5vw, 3.75rem);
	--page-spacing--fixed: 2.1875rem;
	--small-header-block-size: 3.625rem;
	--large-header-block-size: calc(4.625rem + var(--layout-border-width));
	--layout-border-width: 0.09375em;
	--layout-border: var(--layout-border-width) solid var(--color-line-grey);
	--line-height: var(--typo-Default-line-height);

	--link-underline-size: 0.09375em;
	--link-underline-offset: 0.4em;
	--link-icon-size: 1.5em;
	--link-icon-offset: calc(var(--link-icon-size) + 0.5em);

	--border-radius: 0.1875em;

	/* fonts */
	--typo-reduced-font-size: var(--typo-DefaultSmall-font-size);
	--typo-small-font-size: 0.75rem;
	--typo-code-font-size: 0.95em;
	--typo-font-weight-bold: 600;

	--typo-h1-font-size: var(--typo-HeadingXXL-font-size);
	--typo-h1-line-height: var(--typo-HeadingXXL-line-height);
	--typo-h1-font-weight: var(--typo-HeadingXXL-font-weight);

	--typo-h2-font-size: var(--typo-HeadingXL-font-size);
	--typo-h2-line-height: var(--typo-HeadingXL-line-height);
	--typo-h2-font-weight: var(--typo-HeadingXL-font-weight);

	--typo-h3-font-size: var(--typo-HeadingM-font-size);
	--typo-h3-line-height: var(--typo-HeadingM-line-height);
	--typo-h3-font-weight: var(--typo-HeadingM-font-weight);

	--typo-h4-font-size: var(--typo-HeadingXS-font-size);
	--typo-h4-line-height: var(--typo-HeadingXS-line-height);
	--typo-h4-font-weight: var(--typo-HeadingXS-font-weight);
}

@media (prefers-contrast: more) {
	html {
		--color-green-light: var(--color-green);
	}
}

body {
	color: var(--color-black);
	display: flex;
	flex-direction: column;
	font-family: SuisseIntl, sans-serif;
	line-height: var(--line-height);
	min-block-size: 100vh;
	min-block-size: 100dvh;
}

.Content {
	flex: 1;
}

@media (min-width: 58.5625em) {
	.Content {
		display: flex;
	}

	main {
		inline-size: 100%;
	}
}

h1,
h2,
h3,
h4,
h5 {
	font-family: SuisseIntl, sans-serif;
}

a {
	color: inherit;
	text-decoration: none;
}

:where(.Header, main, .Footer) a:not([class]),
.Pagination-link {
	text-decoration: underline;
	text-decoration-thickness: var(--link-underline-size);
	text-underline-offset: var(--link-underline-offset);
}

:where(.Header, main, .Footer) :where(a, button):focus,
.Header-search:focus-within:has(.DocSearch-Button:focus-visible),
.Header-searchSubmit:focus {
	outline: 0.15em solid var(--color-blue);
}

:where(.Header, main, .Footer) a:not(.Nav-listItemLink, .header-anchor):focus,
:where(.Header, main, .Footer) button:focus,
.Header-search:focus-within:has(.DocSearch-Button:focus-visible),
.Header-searchSubmit:focus {
	outline-offset: 0.25em;
}

:where(.Header, main, .Footer) a:where([target="_blank"],[href^="https://"]):not([class]),
.Pagination-link {
	position: relative;
}

:where(.Header, main, .Footer) a:where([target="_blank"],[href^="https://"]):not([class])
{
	margin-inline-end: calc(var(--link-icon-offset) + 0.5ch);
}

:where(.Header, main, .Footer) a:where([target="_blank"],[href^="https://"]):not([class])::after
{
	background: url("../icons/external-link-black.svg") right no-repeat;
	background-size: var(--link-icon-size);
	block-size: var(--link-icon-size);
	content: "";
	inline-size: var(--link-icon-size);
	margin-inline-start: 0.25rem;
	position: absolute;
}

.Nav-listItemLinkLabel,
.FooterList-link {
	text-decoration: none;
	text-decoration-color: transparent;
	text-decoration-thickness: var(--link-underline-size);
	text-underline-offset: var(--link-underline-offset);
	transition: all 0.1s ease;
}

.Nav-listItemLink:hover .Nav-listItemLinkLabel,
.FooterList-link:hover {
	text-decoration: underline;
	text-decoration-color: currentColor;
	text-decoration-thickness: var(--link-underline-size);
	text-underline-offset: var(--link-underline-offset);
}
