/* @define SkipToContentLink */

.SkipToContentLink {
	background: var(--color-green-light);
	color: var(--color-white);
	inset-block-start: 0;
	inset-inline-start: 50%;
	padding: 0.125em 0.5em;
	position: absolute;
	transform: translateX(-50%);
}

.SkipToContentLink:not(:focus) {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
