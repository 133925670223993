/* @define FooterList */

.FooterList {
	display: flex;
	gap: 2rem;
	list-style: none;
}

.FooterList-link:hover {
	color: var(--color-white);
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
.FooterList-link--social .Icon {
	font-size: 1.5rem;
}
