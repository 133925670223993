.u-hiddenVisually {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

@media (max-width: 58.5em) {
	.u-container {
		padding-inline: var(--page-spacing--fluid);
	}
}

@media (min-width: 58.5625em) {
	.u-container {
		padding-inline: var(--page-spacing--fixed);
	}
}
