/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

:where(
	html,
	body,
	div,
	span,
	applet,
	object,
	iframe,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	blockquote,
	pre,
	a,
	abbr,
	acronym,
	address,
	big,
	cite,
	code,
	del,
	dfn,
	em,
	img,
	ins,
	kbd,
	q,
	s,
	samp,
	small,
	strike,
	strong,
	sub,
	sup,
	tt,
	var,
	b,
	u,
	i,
	center,
	dl,
	dt,
	dd,
	ol,
	ul,
	li,
	fieldset,
	form,
	label,
	legend,
	table,
	caption,
	tbody,
	tfoot,
	thead,
	tr,
	th,
	td,
	article,
	aside,
	canvas,
	details,
	embed,
	figure,
	figcaption,
	footer,
	header,
	hgroup,
	menu,
	nav,
	output,
	ruby,
	section,
	summary,
	time,
	mark,
	audio,
	video
) {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
}
:where(body) {
	line-height: 1.5;
}
:where(blockquote, q) {
	quotes: none;
}
:where(blockquote, q)::before,
:where(blockquote, q)::after {
	content: none;
}
:where(table) {
	border-collapse: collapse;
	border-spacing: 0;
}

/* CUSTOM */

:where(button, input:not([type="checkbox"], [type="radio"]), select, textarea) {
	-webkit-appearance: none;
	appearance: none;
	background: none;
	border-radius: 0;
	border: none;
	color: inherit;
	font: inherit;
	margin: 0;
	padding: 0;
}

/* It is important to keep the outlines when elements are focused because
 * a user uses the keyboard to navigate. We can use the pseudo class
 * :focus-visible for that. Only if this does not apply, we can safely
 * remove outlines when an element is focused (by using the mouse e.g.)
 */
:where(a, button, input, select, summary, textarea):focus:not(:focus-visible) {
	outline: none;
}

/* When setting list-style to "none" for lists, it is also semantically removed.
 * By adding role=list, it will keep its semantic meaning, so in that case
 * we can set list-style to "none".
 */
:where(ul, ol)[role="list"] {
	list-style: none;
}

/* Make sure images etc do not have a small gap between them and their parent
 * and make sure they do not overflow their parent.
 */
:where(img, picture, video, canvas, svg) {
	display: block;
	max-width: 100%;
}

/* Make sure words that are too long for one line wrap. */
:where(
	div,
	span,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	blockquote,
	a,
	abbr,
	acronym,
	address,
	big,
	cite,
	del,
	dfn,
	em,
	ins,
	q,
	s,
	samp,
	small,
	strike,
	strong,
	sub,
	sup,
	tt,
	b,
	u,
	i,
	dt,
	dd,
	li,
	fieldset,
	form,
	label,
	legend,
	caption,
	th,
	td,
	article,
	aside,
	details,
	figure,
	figcaption,
	footer,
	header,
	hgroup,
	menu,
	nav,
	output,
	ruby,
	section,
	summary,
	time,
	mark
) {
	overflow-wrap: break-word;
}
