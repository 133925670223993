@import "./search.css";

/* @define Header */

.Header {
	background-color: var(--color-background);
	border-block-end: var(--layout-border);
	inset-block-start: 0;
	position: sticky;
	z-index: 1;
}

.Header-content {
	align-items: center;
	box-sizing: border-box;
	display: flex;
	gap: 2rem;
}

.Header-meta {
	align-items: center;
	display: flex;
	gap: 2rem;
	margin-inline-start: auto;
}

.Header-navLink,
.Header-logo {
	flex-shrink: 0;
}

.Header-logoImage {
	block-size: calc(var(--block-size) / 16 * 1rem);
	inline-size: calc(var(--inline-size) / 16 * 1rem);
}

@media (max-width: 58.5em) {
	.Header-content {
		block-size: var(--small-header-block-size);
		gap: 0.625rem;
	}

	.Header-navLink {
		--icon-block-size-nav: 0.875em;
		--icon-padding-nav: calc(
			(var(--small-header-block-size) - var(--icon-block-size-nav)) / 2
		);
		block-size: var(--icon-block-size-nav);
		margin-inline-start: calc(-1 * var(--icon-padding-nav));
		padding: var(--icon-padding-nav);
	}

	.Header-navLinkIcon {
		block-size: 1rem;
		fill: currentColor;
		inline-size: 1rem;
	}
}

@media (min-width: 58.5625em) {
	.Header-content {
		block-size: var(--large-header-block-size);
	}

	.Header-navLink {
		display: none;
	}
}
