html {
	--typo-Default-font-family: SuisseIntl, -apple-system, BlinkMacSystemFont,
		"Segoe UI", Roboto, Helvetica, Arial, sans-serif;
	--typo-Default-font-size: 1rem;
	--typo-Default-line-height: 1.75;
	--typo-Default-letter-spacing: 0.03em;

	--typo-DefaultSmall-font-size: 0.875rem;
	--typo-DefaultSmall-line-height: 1.75;
	--typo-DefaultSmall-letter-spacing: 0.03em;

	--typo-DefaultLarge-font-size: 1.25rem;
	--typo-DefaultLarge-line-height: calc(32 / 20);
	--typo-DefaultLarge-letter-spacing: 0.01em;

	--typo-HeadingXXL-font-size: 3.375rem; /* 54px */
	--typo-HeadingXXL-line-height: calc(60 / 54);
	--typo-HeadingXXL-font-weight: 400;

	--typo-HeadingXL-font-size: 2.5rem; /* 40px */
	--typo-HeadingXL-line-height: calc(48 / 40);
	--typo-HeadingXL-font-weight: 400;

	--typo-HeadingL-font-size: 2rem; /* 32px */
	--typo-HeadingL-line-height: calc(40 / 32);
	--typo-HeadingL-font-weight: 400;

	--typo-HeadingM-font-size: 1.875rem; /* 30px */
	--typo-HeadingM-line-height: calc(40 / 30);
	--typo-HeadingM-font-weight: 400;

	--typo-HeadingS-font-size: 1.75rem; /* 28px */
	--typo-HeadingS-line-height: calc(40 / 28);
	--typo-HeadingS-font-weight: 400;

	--typo-HeadingXS-font-size: 1.5rem; /* 24px */
	--typo-HeadingXS-line-height: calc(32 / 24);
	--typo-HeadingXS-font-weight: 400;

	--typo-HeadingXXS-font-size: 1.25rem; /* 20px */
	--typo-HeadingXXS-line-height: calc(24 / 20);
	--typo-HeadingXXS-font-weight: 400;

	--typo-Mono-font-size: 1rem;
	--typo-Mono-line-height: 1.75;
	--typo-Mono-font-weight: 400;
	--typo-Mono-font-family: SuisseIntlMono;
}
