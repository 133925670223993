@font-face {
	font-display: swap;
	font-family: SuisseIntlMono;
	font-style: normal;
	src:
		local(SuisseIntlMono-Regular),
		url("../../fonts/SuisseIntlMono-Regular-WebS.woff2") format("woff2");
}

@font-face {
	font-display: swap;
	font-family: SuisseIntlMonoBold;
	font-style: normal;
	font-weight: bold;
	src:
		local(SuisseIntlMono-Bold),
		url("../../fonts/SuisseIntlMono-Bold-WebS.woff2") format("woff2");
}

@font-face {
	font-display: swap;
	font-family: SuisseIntl;
	font-style: normal;
	font-weight: normal;
	src:
		local(SuisseIntl-Regular),
		url("../../fonts/SuisseIntl-Regular.woff2") format("woff2");
}

@font-face {
	font-display: swap;
	font-family: SuisseIntl;
	font-style: normal;
	font-weight: 600;
	src:
		local(SuisseIntl-SemiBold),
		url("../../fonts/SuisseIntl-SemiBold.woff2") format("woff2");
}
