/* @define Footer */

.Footer {
	background-color: var(--color-footer);
}

.Footer-wrapper {
	--Footer-spacing: 4rem;

	color: var(--color-footer-links);
	padding-block: var(--Footer-spacing);
}

.Footer-wrapper + .Footer-wrapper {
	margin-block-start: calc(var(--Footer-spacing) * -1);
}

.Footer-logo {
	block-size: calc(var(--block-size) / 16 * 1rem);
	inline-size: calc(var(--inline-size) / 16 * 1rem);
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
.Footer-credit.Footer-credit a::after {
	/* double class selector to increase specificity */
	background-image: url("../../icons/eternal-link-grey.svg");
}

@media (min-width: 58.5625em) {
	.Footer-wrapper {
		display: flex;
		justify-content: space-between;
		padding-inline: var(--page-spacing--fixed);
	}
}

@media (max-width: 58.5em) {
	.Footer-wrapper {
		padding-inline: var(--page-spacing--fluid);
	}

	.Footer-list + .Footer-list,
	.Footer-credit {
		margin-block-start: var(--Footer-spacing);
	}
}
