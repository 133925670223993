:root {
	--docsearch-modal-background: var(--color-background-grey);
	--docsearch-primary-color: var(--color-grey-900);
	--docsearch-text-color: var(--color-black);
	--docsearch-muted-color: var(--color-black);
	--docsearch-container-background: #2a2a2a99;
	--docsearch-logo-color: var(--color-black);
	--docsearch-highlight-color: var(--docsearch-primary-color);
	--docsearch-searchbox-background: none;
	--docsearch-searchbox-focus-background: var(--color-background);
	--docsearch-hit-active-color: var(--color-white);
	--docsearch-hit-background: var(--color-background);
	--docsearch-footer-background: var(--color-background);
	--docsearch-button-key-color: var(--color-grey-500);
	--docsearch-input-placeholder: var(--color-grey-500);

	--docsearch-hit-color: #444950;
	--docsearch-key-gradient: none;

	--docsearch-hit-shadow: 0 1px 3px 0 #d4d9e1;
	--docsearch-modal-shadow: inset 1px 1px 0 0 hsla(0, 0%, 100%, 0.5),
		0 3px 8px 0 #555a64;
	--docsearch-searchbox-shadow: none;
	--docsearch-key-shadow-color: var(--docsearch-button-key-color);
	--docsearch-key-shadow: 0 0 0 1px var(--docsearch-key-shadow-color);
	--docsearch-footer-shadow: 0 -1px 0 0 #e0e3e8,
		0 -3px 6px 0 rgba(69, 98, 155, 0.12);

	--docsearch-spacing: 12px;
	--docsearch-icon-stroke-width: 1.4;
	--docsearch-modal-width: 560px;
	--docsearch-modal-height: 600px;
	--docsearch-searchbox-height: 56px;
	--docsearch-hit-height: 56px;
	--docsearch-footer-height: 44px;
}

.Header-search {
	--search-border: 0.09375rem solid var(--docsearch-primary-color);
	--search-border-radius: var(--border-radius);
	--icon-size-search: 0.625rem;
	--icon-padding-search: calc(
		(var(--searchbox-block-size) - var(--icon-size-search)) / 2
	);
	--searchbox-block-size: 2.5rem;
}

.Header-searchForm {
	align-items: center;
	display: flex;
}

.Header-searchLabel {
	position: absolute;
}

.Header-searchLabel,
.DocSearch-Button-Container {
	padding-inline: var(--icon-padding-search);
}

.Header-searchLabelIcon,
.DocSearch-Search-Icon {
	block-size: 1rem;
	inline-size: 1rem;
}

.Header-searchLabelIcon {
	fill: var(--docsearch-primary-color);
}

.DocSearch-Button .DocSearch-Search-Icon {
	color: var(--docsearch-primary-color);
}

.DocSearch-Search-Icon path {
	stroke-width: 0.125em;
}

.Header-searchInput {
	block-size: var(--searchbox-block-size);
	box-sizing: border-box;
	padding-inline-start: calc(
		var(--icon-padding-search) * 2 + var(--icon-size-search)
	);
	position: relative;
}

.Header-searchInput:focus-visible {
	outline: none;
}

.Header-searchSubmit {
	color: var(--docsearch-primary-color);
	font-size: var(--typo-reduced-font-size);
	line-height: 1.1;
	margin-inline: 0.625rem;
}

.Header-searchForm:not(:focus-within) .Header-searchSubmit {
	height: 0;
	margin: 0;
	overflow: hidden;
	width: 0;
}

@media (max-width: 58.5em) {
	.Header-search--noscript:focus-within {
		border: var(--search-border);
		border-radius: var(--search-border-radius);
		flex-grow: 1;
		margin-inline-start: 0.625rem;
	}

	.Header-search--noscript:focus-within + .Header-githubLink {
		display: none;
	}

	.Header-search--noscript:focus-within .Header-searchInput {
		inline-size: 100%;
	}

	.Header-search--noscript:not(:focus-within) .Header-searchInput {
		inline-size: var(--searchbox-block-size);
	}
}

@media (min-width: 58.5625em) {
	.Header-search {
		--input-size: 14.375rem;

		border: var(--search-border);
		border-radius: var(--border-radius);
	}

	.Header-searchInput,
	.DocSearch-Button {
		inline-size: var(--input-size);
	}
}

.DocSearch-Button {
	cursor: text;
	height: 100%;
	margin: 0;
	padding: 0;
}

.DocSearch-Button-Placeholder {
	display: none;
}

.DocSearch-Button-Keys {
	font-size: var(--typo-reduced-font-size);
	margin-inline-end: 0.5em;
}

.DocSearch-Button-Key {
	block-size: 1.125rem;
	color: var(--docsearch-button-key-color);
	inline-size: 1.25rem;
	padding: 0;
	top: 0;
}

.DocSearch-Input::placeholder {
	color: var(--docsearch-input-placeholder);
}

#docsearch {
	height: var(--searchbox-block-size);
}
