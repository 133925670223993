html {
	--color-green-light: hsl(163 100% 39%);
	--color-green: hsl(164 100% 26%);
	--color-blue: hsl(205 100% 32%);
	--color-blue-light: hsl(199 100% 43%);
	--color-orange: hsl(15 78% 46%);
	--color-orange-light: hsl(15 100% 63%);
	--color-blue-dark: hsl(207 61% 15%);

	--color-grey-900: hsl(221 39% 11%);
	--color-grey-500: hsl(221 11% 49%);
	--color-grey-400: hsl(221 11% 62%);
	--color-grey-300: hsl(221 26% 84%);
	--color-grey-200: hsl(221 27% 94%);
	--color-grey-100: hsl(216 33% 97%);
	--color-white: hsl(0 0% 100%);

	--color-black: var(--color-grey-900);
	--color-text: var(--color-black);
	--color-background: var(--color-white);
	--color-background-grey: var(--color-grey-100);
	--color-line-grey: var(--color-grey-200);
	--color-footer: var(--color-black);
	--color-footer-links: var(--color-grey-400);
}
